var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.breadcrumbs.length)?_c('div',{staticClass:"d-flex mb-6"},[_c('div',{class:_vm.$style.textMedium,on:{"click":function($event){return _vm.go_to_start()}}},[_vm._v(" Маркетинговые материалы для партнеров ")]),_c('v-icon',{staticClass:"ml-2 mr-2"},[_vm._v("mdi-chevron-right")]),_vm._l((_vm.breadcrumbs),function(item,index){
var _obj;
return _c('div',{key:item.path,staticClass:"ml-2 mr-2",class:( _obj = {}, _obj[_vm.$style.textMedium] = index !== _vm.breadcrumbs.length - 1, _obj[_vm.$style.breadItem] = index === _vm.breadcrumbs.length - 1, _obj ),on:{"click":function($event){index !== _vm.breadcrumbs.length - 1 && _vm.go_to(item.path)}}},[_vm._v(" "+_vm._s(item.title)+" "),(index !== _vm.breadcrumbs.length - 1)?_c('v-icon',[_vm._v("mdi-chevron-right")]):_vm._e()],1)})],2):_vm._e(),_c('PageTitle',{attrs:{"hide_btns":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [(!_vm.breadcrumbs.length)?_c('div',[_vm._v("Маркетинговые материалы для партнеров ")]):_c('div',[_vm._v(_vm._s(_vm.breadcrumbs[_vm.breadcrumbs.length - 1].title))])]},proxy:true}])}),_c('v-card',{class:_vm.$style.card},[_c('Search',{class:_vm.$style.searchForm,attrs:{"dense":"","label":"Поиск по названию папки/файла"},on:{"clear":function($event){return _vm.filter_update('search', '')},"submit":function($event){return _vm.filter_update('search', $event)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.submit_search.apply(null, arguments)}},model:{value:(_vm.search_text),callback:function ($$v) {_vm.search_text=$$v},expression:"search_text"}}),_c('Table',{class:_vm.$style.table,attrs:{"headers":_vm.cols,"items":_vm.materials,"loading":_vm.materials_loading,"options":_vm.table_options,"custom-sort":_vm.prevent_sort,"no-data-text":"Материалы, удовлетворяющие выбранным параметрам, отсутствуют","hide-default-footer":"","not_resizeble":""},on:{"update:options":function($event){_vm.table_options=$event},"click:row":_vm.row_click_handler},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"d-flex align-center py-2"},[(_vm.get_icon(item.slug))?_c('v-icon',{staticClass:"mr-4"},[_vm._v(" mdi-folder-text-outline ")]):_c('v-icon',{staticClass:"mr-4"},[_vm._v(" mdi-file-document-outline ")]),_c('span',[_vm._v(_vm._s(item.title))])],1)])]}},{key:"item.extension",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.extension || '-')+" ")]}},{key:"item.last_modified_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.last_modified_at))+" ")]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.size || '-')+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","small":"","color":"accent darken-4","disabled":!item.download_link},on:{"click":function($event){$event.stopPropagation();return _vm.handle_download(item.download_link)}}},[_c('v-icon',[_vm._v("mdi-tray-arrow-down")])],1)]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination_props":_vm.pagination_props},on:{"change_page":function($event){return _vm.upd_pagination('current_page', $event)},"page_size_changed":function($event){return _vm.upd_pagination('limit', $event)}}})]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }